const { salaries } = require('../shared/data/salaries');
// const { blogPosts } = require('../shared/data/blogPosts');
const { kebabCase } = require('lodash');

const routes = {
  calculator: {
    path: '/calculator',
    name: 'Calculator',
    component: () => import('@/components/Calculator.vue'),
    alias: ['/'],
    meta: {
      title: 'מחשבון שכר ברוטו נטו',
    },
  },
  calculatorResult: {
    path: '/calculator-result',
    name: 'CalculatorResult',
    component: () => import('@/components/Calculator.vue'),
    meta: {
      title: 'מחשבון שכר ברוטו נטו',
      excludeFromSitemap: true,
    },
  },
  tutorial: {
    path: '/money-saving-tutorial',
    name: 'Tutorial',
    component: () => import('@/components/tutorial/Tutorial.vue'),
    meta: {
      title: 'טיפים כדי להישאר עם יותר כסף בבנק',
      description: 'רוצה לדעת איך להישאר עם יותר כסף בבנק?',
    },
  },
  privacyPolicyEN: {
    path: '/privacy-policy/en',
    name: 'PrivacyPolicyEN',
    component: () => import('@/components/compliance/PrivacyPolicyEN.vue'),
    meta: {
      title: 'Privacy policy',
      description: 'Kama Dugri privacy policy',
    },
  },
  privacyPolicyHE: {
    path: '/privacy-policy/he',
    name: 'PrivacyPolicyHE',
    component: () => import('@/components/compliance/PrivacyPolicyHE.vue'),
    meta: {
      title: 'מדיניות פרטיות',
      description: 'מדיניות הפרטיות של כמה דוגרי',
    },
  },
  notFound: {
    path: '/not-found',
    name: 'PageNotFound',
    component: () => import('@/components/PageNotFound.vue'),
    meta: {
      title: 'שגיאה',
      excludeFromSitemap: true,
    },
  },
  // dynamic salaries routes
  ...salaries.reduce((map, salary) => {
    map[salary.key] = {
      path: `/salaries/${kebabCase(salary.key)}`,
      name: salary.key,
      component: () => import('@/components/Calculator.vue'),
      meta: {
        title: `כמה ${salary.title}`,
        description: `רוצה לדעת כמה ${salary.title}?`,
      },
    };
    return map;
  }, {}),
  // dynamic blog posts routes
  /* ...blogPosts.reduce((map, blogPost) => {
    map[blogPost.key] = {
      path: `/blog/${kebabCase(blogPost.key)}`,
      name: blogPost.key,
      component: () => import('@/components/articles/BlogPost.vue'),
      meta: {
        title: blogPost.meta.mainTitle,
        description: blogPost.meta.secondaryTitle,
      },
    };
    return map;
  }, {}),
  fallback: {
    path: '/:catchAll(.*)',
    redirect: '/not-found',
  },*/
};

module.exports = { routes };
