<template>
  <div
    :class="[
      'app-header',
      {
        'app-header--no-slogan': isHeaderSubtitleHidden,
      },
    ]"
  >
    <a :href="siteLink" class="app-header__logo-link">
      <div class="app-header__logo">
        <img
          class="app-header__logo-image"
          src="../assets/logo.png"
          alt="Kama dugri application header logo"
        />
        <span class="app-header__logo-text">
          <span class="app-header__logo-text-title">כמה דוגרי</span>
          <h1
            :class="[
              'app-header__logo-text-subtitle',
              {
                'app-header__logo-text-subtitle--hidden':
                  isHeaderSubtitleHidden,
              },
            ]"
          >
            מחשבון שכר ברוטו נטו
          </h1>
        </span>
      </div>
    </a>
    <div class="app-header__share-button" @click="shareWebsite">
      <span>שיתוף</span>
      <img
        class="app-header__share-icon"
        src="../assets/share-icon.png"
        alt="Share"
      />
    </div>
  </div>
  <div class="app-body">
    <div
      :class="[
        'app-body__view-wrapper',
        { 'app-body__view-wrapper--pc': !isMobile },
      ]"
    >
      <router-view class="app-body__view" />
      <div class="app-body__footer">
        <router-link
          class="app-body__privacy-policy"
          :to="routes.privacyPolicyHE.path"
        >
          מדיניות פרטיות
        </router-link>
        |
        <router-link
          class="app-body__privacy-policy"
          :to="routes.privacyPolicyEN.path"
        >
          Privacy policy
        </router-link>
      </div>
    </div>
    <div class="app-body__emoji-explosion-container"></div>
  </div>
</template>

<script>
import mobileMixin from '@/shared/mixins/mobile.mixin';
import { mapGetters } from 'vuex';
import { routes } from '@/router/routes';

export default {
  name: 'SiteApp',
  mixins: [mobileMixin],
  data() {
    return {
      routes,
    };
  },
  computed: {
    ...mapGetters('calculator', ['isIncomeSubmitted']),
    isHeaderSubtitleHidden() {
      return this.isIncomeSubmitted && this.isMobile;
    },
    siteLink() {
      return location.origin;
    },
  },
  methods: {
    shareWebsite() {
      navigator.share({
        title: 'מחשבון שכר ברוטו נטו | כמה דוגרי',
        text: document.title,
        url: location.href,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../shared/style/_colors.scss';
.app-header {
  $base: &;

  padding: 0.4rem;
  border-bottom: 1px solid lightgrey;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.1s linear;

  &--no-slogan {
    height: 22px;

    #{$base} {
      &__logo-image {
        height: 1.5rem;
      }

      &__share-icon {
        height: 1.3rem;
      }
    }
  }

  &__logo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.1s linear;
    height: inherit;

    &-link {
      text-decoration: none;
    }

    &-image {
      height: 2.5rem;
      margin-left: 5px;
    }

    &-text {
      &-title {
        font-weight: bold;
        color: $dark-green;
        font-size: 1.3rem;
      }

      &-subtitle {
        font-size: 1rem;
        margin: unset;
        font-weight: normal;
        color: $black;

        &--hidden {
          display: none;
        }
      }
    }
  }

  &__share {
    &-button {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: darken($dark-green, 10%);
      font-weight: bold;
    }

    &-icon {
      height: 1.5rem;
      margin-right: 0.4rem;
      transition: all 0.1s linear;
    }
  }
}

.app-body {
  &__emoji-explosion-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }

  &__view {
    grid-row-start: 2;
  }

  &__view-wrapper {
    position: relative;
    min-height: calc(100vh - 60px);
    display: grid;
    grid-template-rows: auto 1fr;
    align-items: center;

    &--pc {
      width: 800px;
      margin: auto;
      box-shadow: 1px 0 $cute-grey, -1px 0 $cute-grey;
      border-top: none;
      border-bottom: none;
      background: $white;
      min-height: calc(100vh - 57px);
    }
  }

  &__footer {
    padding: 15px 10px;
    grid-row-start: 4;
    margin: auto;
  }

  &__privacy-policy {
    color: $quote-grey;
  }
}
</style>
