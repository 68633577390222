<template>
  <site-app />
</template>

<script>
import SiteApp from '@/components/SiteApp';

export default {
  name: 'App',
  components: {
    SiteApp,
  },
};
</script>

<style lang="scss">
@import './shared/style/_fonts.scss';

* {
  direction: rtl;
  font-family: 'Rubik', sans-serif;
}

body {
  margin: 0;
}
</style>
