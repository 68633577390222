<template>
  <div class="article-base">
    <div class="article-base__header">
      <div class="article-base__header-title">
        <h1 class="article-base__header-title-main">
          {{ mainTitle }}
        </h1>
        <div class="article-base__header-title-secondary">
          {{ secondaryTitle }}
        </div>
      </div>
      <div class="article-base__header-background-wrapper">
        <img class="article-base__header-background" :src="titleImagePath" />
      </div>
      <div class="article-base__header-details">
        <b>{{ readingTimeMinutes }} דקות קריאה</b> | {{ lastUpdatedText }}
      </div>
    </div>
    <div class="article-base__body">
      <slot></slot>
      <router-link :to="calculatorLink" class="article-base__redirect-link">
        <div>חזרה למחשבון השכר</div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { routes } from '@/router/routes';

export default {
  name: 'ArticleBase',
  props: {
    mainTitle: {
      type: String,
      required: true,
    },
    secondaryTitle: {
      type: String,
      required: true,
    },
    readingTimeMinutes: {
      type: Number,
      default: 4,
    },
    titleImageFileName: {
      type: String,
      default: 'tutorial-background.jpg',
    },
    lastUpdatedDate: {
      type: Date,
      default: new Date(),
    },
  },
  data() {
    return {
      calculatorLink: routes.calculator.path,
    };
  },
  computed: {
    titleImagePath() {
      return require(`@/assets/${this.titleImageFileName}`);
    },
    lastUpdatedText() {
      return `עודכן לאחרונה ב-${this.lastUpdatedDate.toLocaleDateString(
        'he-IL'
      )}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/style/_colors.scss';

.article-base {
  grid-row-start: unset;

  &__header {
    border-bottom: 1px solid $cute-grey;

    &-background {
      height: 160px;
      width: calc(100% - 20px);
      margin: auto;
      object-fit: cover;
      object-position: 75% 25%;
      border-radius: 10px;
      box-shadow: 0 4px 5px rgb(0 0 0 / 32%);
    }

    &-background-wrapper {
      text-align: center;
      margin-top: -100px;
    }

    &-title {
      color: $white;
      background: linear-gradient($dark-green, $very-dark-green);
      padding: 20px 20px 130px 20px;
    }

    &-title-main {
      font-size: 1.6rem;
      font-weight: bold;
      letter-spacing: 0.07rem;
      margin: 0 0 10px 0;
    }

    &-subtitle {
      margin-top: 10px;
      letter-spacing: 0.03rem;
    }

    &-details {
      font-size: 0.9rem;
      margin: 15px;
      letter-spacing: 0.03rem;
      line-height: 1.2rem;
      color: $quote-grey;
    }
  }

  &__body {
    padding: 0 15px;
  }

  &__redirect-link {
    text-align: center;
    margin: 10px auto;
    font-weight: bold;
    color: darken($dark-green, 10%);
    display: block;
  }
}
</style>
