const { educationFundStatuses } = require('../constants/educationFundStatuses');

const salaries = [
  {
    income: 26500,
    educationFundStatus: educationFundStatuses.ceiling,
    title: 'מפתח תוכנה עם שנתיים נסיון מרוויח בנטו',
    key: 'SoftwareEngineer',
  },
  {
    income: 85334,
    educationFundStatus: educationFundStatuses.ceiling,
    title: 'שופטת בבית המשפט העליון מרוויחה בנטו',
    key: 'Judge',
  },
  {
    income: 35742,
    educationFundStatus: educationFundStatuses.ceiling,
    title: 'עובד בנמל חיפה מכניס נטו',
    key: 'HaifaPortEmployee',
  },
  {
    income: 5300,
    educationFundStatus: educationFundStatuses.none,
    title: 'עובד בשכר מינימום מכניס בנטו',
    key: 'MinimumWageEmployee',
  },
  {
    income: 14000,
    educationFundStatus: educationFundStatuses.ceiling,
    title: 'מעצבת UX מרוויחה בנטו',
    key: 'UxDesigner',
  },
  {
    income: 26500,
    educationFundStatus: educationFundStatuses.ceiling,
    title: 'מנהלת מוצר עם 4 שנות נסיון מכניסה נטו',
    key: 'ProductManager',
  },
  {
    income: 18000,
    educationFundStatus: educationFundStatuses.ceiling,
    title: 'מנתח מערכות מרוויח בנטו',
    key: 'SystemAnalyst',
  },
  {
    income: 15000,
    educationFundStatus: educationFundStatuses.ceiling,
    title: 'מעצב גרפי עם 5 שנות נסיון מכניס נטו',
    key: 'GraphicDesigner',
  },
  {
    income: 24000,
    educationFundStatus: educationFundStatuses.ceiling,
    title: 'מהנדס DevOps עם שנת נסיון אחת מרוויח בנטו',
    key: 'DevOpsEngineer',
  },
  {
    income: 21500,
    educationFundStatus: educationFundStatuses.ceiling,
    title: 'מנהל משאבי אנוש מרוויח בנטו',
    key: 'HrManager',
  },
  {
    income: 29500,
    educationFundStatus: educationFundStatuses.whole,
    title: 'מנהלת שיווק בין לאומי מרוויחה בנטו',
    key: 'InternationalMarketingManager',
  },
  {
    income: 53500,
    educationFundStatus: educationFundStatuses.whole,
    title: 'סמנכ"לית פיתוח עסקי מכניסה נטו',
    key: 'VpBusinessDevelopment',
  },
  {
    income: 16000,
    educationFundStatus: educationFundStatuses.none,
    title: 'כלכלנית בשירות המדינה מרוויחה בנטו',
    key: 'EconomistPublicSector',
  },
  {
    income: 13500,
    educationFundStatus: educationFundStatuses.none,
    title: 'משפטן בשירות הציבורי מכניס נטו',
    key: 'JuristPublicSector',
  },
  {
    income: 10900,
    educationFundStatus: educationFundStatuses.none,
    title: 'עובדת בלשכת שר מרוויחה נטו',
    key: 'MinisterOfficeEmployee',
  },
];

module.exports = { salaries };
