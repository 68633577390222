import { createStore } from 'vuex';
import { calculator } from './modules/calculator.js';

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    calculator,
  },
});
