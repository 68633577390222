<template>
  <div class="article-section">
    <h2 class="article-section__title">
      <a
        class="article-section__anchor"
        :id="title"
        :name="title"
        :href="anchorLink"
        >{{ title }}
      </a>
    </h2>
    <p
      v-for="paragraph in paragraphs"
      :key="paragraph"
      class="article-section__paragraph"
    >
      {{ paragraph }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'ArticleSection',
  props: {
    title: {
      type: String,
      required: true,
    },
    paragraphs: {
      type: Array,
      required: true,
    },
  },
  computed: {
    anchorLink() {
      return `${location.origin + location.pathname}#${this.title}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/style/_colors.scss';

.article-section {
  &__title {
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 1.8rem;
  }

  &__anchor {
    text-decoration: none;
    color: $black;
  }

  &__paragraph {
    line-height: 1.7rem;
  }
}
</style>
