import { createWebHistory, createRouter } from 'vue-router';
import { routes } from './routes';

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to) {
    if (to.hash) {
      return { el: to.hash };
    }
  },
  routes: Object.values(routes),
});

const titleBase = 'כמה דוגרי';
const metaDescriptionBase =
  'כמה דוגרי הוא המחשבון הכי מהיר וקל לשימוש. קליק אחד כדי להפוך את השכר ברוטו לנטו';
const metaDescriptionFallback = 'רוצה לדעת מה המשכורת ברוטו לנטו?';
router.afterEach((to) => {
  document.title = getTitle(to);
  document
    .querySelector('meta[name=description]')
    .setAttribute('content', getDescription(to));
});

const getTitle = (routeObj) => {
  const routeTitle = routeObj?.meta?.title;
  if (routeTitle) {
    return `${routeTitle} | ${titleBase}`;
  } else {
    return titleBase;
  }
};

const getDescription = (routeObj) => {
  const routeDescription = routeObj?.meta?.description;
  return `${
    routeDescription ?? metaDescriptionFallback
  } ${metaDescriptionBase}`;
};

export default router;
